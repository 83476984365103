<template>
  <esmp-modal
    :value="showed"
    class-name="modal-confirm-action"
    :closable="false"
    :mask-closable="false"
  >
    <template #header v-if="header">
      {{ header }}
    </template>
    {{ content }}
    <template #footer>
      <esmp-button @click="$emit('confirm-action')">
        Ок
      </esmp-button>
      <esmp-button view="outline" @click="$emit('cancel-action')">
        Отмена
      </esmp-button>
    </template>
  </esmp-modal>
</template>

<script>
export default {
  name: 'ModalConfirmAction',
  props: {
    showed: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
  },
};
</script>
